
@font-face {
    font-family: Poppins;
    src: url('../assets/font/Poppins-Regular.ttf');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: Poppins;
    src: url('../assets/font/Poppins-Bold.ttf');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: Poppins;
    src: url('../assets/font/Poppins-ExtraBold.ttf');
    font-weight: 600;
    font-style: normal;
}